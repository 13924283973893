<template>
  <b-card-code>
    <b-card-body>
      <h2 class="text-primary">
          Define document structure
      </h2>
      <b-card-text class="mb-2">
          <span>Vivamus in suscipit nisl. Praesent rutrum vestibulum suscipit. Nullam at lacus id mauris fringilla imperdiet at in purus. Vivamus pellentesque ipsum in ultrices porttitor.</span>
      </b-card-text>
    </b-card-body>
    <b-row class="d-flex justify-content-end">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
        >
          <span class="align-middle mr-50">Export</span>
          <feather-icon
            icon="EditIcon"
          />
        </b-button>
      </b-col>
    </b-row>
    
    <b-row class="ml-50"> 
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'avatar'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.avatar"
              class="mx-1"
            />
          </span>

          <!-- Column: Name -->
          <span
            v-else-if="props.column.field === 'example1'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.example1 }}</span>
          </span>

          <span
            v-else-if="props.column.field === 'example2'"
            class="text-nowrap"
          >
              <b-form-group>
                  <b-form-input
                      placeholder="Insert Value"
                  />
              </b-form-group>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'example4'">
            <b-badge :variant="statusVariant(props.row.example4)">
              {{ props.row.example4 }}
            </b-badge>
          </span>

          <span
            v-else-if="props.column.field === 'example5'"
            class="text-nowrap"
          >
              <b-form-group>
                  <v-select
                      v-model="selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="option"
                  />
              </b-form-group>
          </span>

          <span
            v-else-if="props.column.field === 'example6'"
            class="text-nowrap"
          >
              <b-form-group>
                  <v-select
                      v-model="selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="option"
                  />
              </b-form-group>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-50"
                />
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-center flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to 7
                </span>
                <span class="text-nowrap"> of 100 entries </span>
              </div>
          <div>
          </div>
          </div>
        </template>
      </vue-good-table>
    </b-row>

    <b-button
        variant="dark"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Save
        <feather-icon
          icon="ChevronRightIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
      </b-button>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BBadge, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BBadge,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      codeBasic,
      selected: { title: 'Select...' },
      option: [{ title: 'Option One' }, { title: 'Option Two' }, { title: 'Option Three' }, { title: 'Option Four' }],
      columns: [
        {
          label: 'Client',
          field: 'avatar',
        },
        {
          label: 'Example',
          field: 'example1',
        },
        {
          label: 'Example',
          field: 'example2',
        },
        {
          label: 'Example',
          field: 'example3',
        },
        {
          label: 'Example',
          field: 'example4',
        },
        {
          label: 'Example',
          field: 'example5',
        },
        {
          label: 'Example',
          field: 'example6',
        },
        {
          label: 'ADD',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      exemple4: [{
        1: 'Inprogress',
        2: 'Completed',
        3: 'Error,'
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Inprogress   : 'light-primary',
        Completed    : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/structure')
      .then(res => { this.rows = res.data })
  },
}
</script>